<template>
  <div>
    <div class="row mx-0 mb-2">
      <div class="col-sm-7">
        <div class="row">
          <div class="col-sm-7 pl-0">
            <strong>Consulta Externa</strong>
          </div>
          <div class="col-sm-2 text-center">
            <strong>Obligatorio</strong>
          </div>
          <div class="col-sm-3 text-center pr-0">
            <strong>Cantidad de Caracteres</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mb-1">
      <div class="col-sm-7" style="padding: 5px;">
        <div class="form-group row bg-row border border-secondary py-1">
          <label for="motivo_consulta" class="col-sm-3 pr-0 col-form-label text-right">
            Motivo de Consulta:
          </label>
          <div class="col-sm-4">
            <b-input-group>
              <input
                type="text"
                class="form-control"
                v-model="parametros.motivoConsulta"
                placeholder="Motivo de Consulta"
                @keyup.enter="updateMotivoConsulta"
                :disabled="isDisabled"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="updateMotivoConsulta"
                  :disabled="isDisabled"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-sm-2 text-center d-flex justify-content-center pl-1">
            <input type="checkbox">
          </div>
          <div class="col-sm-3">
            <input type="text" class="form-control" disabled=true>
          </div>
        </div>
        <div class="form-group row bg-row border border-secondary py-1">
          <label for="enfermedad_actual" class="col-sm-3 pr-0 col-form-label text-right">
            Enfermedad Actual:
          </label>
          <div class="col-sm-4">
            <b-input-group>
              <input
                type="text"
                class="form-control"
                v-model="parametros.enfermedadActual"
                placeholder="Enfermedad Actual"
                @keyup.enter="updateEnfermedadActual"
                :disabled="isDisabled"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="updateEnfermedadActual"
                  :disabled="isDisabled"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-sm-2 text-center d-flex justify-content-center pl-1">
            <input type="checkbox">
          </div>
          <div class="col-sm-3">
            <input type="text" class="form-control" disabled=true>
          </div>
        </div>
        <div class="form-group row bg-row border border-secondary py-1">
          <label for="exame_fisico" class="col-sm-3 pr-0 col-form-label text-right">
            Examen Fisico:
          </label>
          <div class="col-sm-4">
            <b-input-group>
              <input
                type="text"
                class="form-control"
                v-model="parametros.examenFisico"
                placeholder="Examen Fisico"
                @keyup.enter="updateExamenFisico"
                :disabled="isDisabled"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="updateExamenFisico"
                  :disabled="isDisabled"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-sm-2 text-center d-flex justify-content-center pl-1">
            <input type="checkbox">
          </div>
          <div class="col-sm-3">
            <input type="text" class="form-control" disabled=true>
          </div>
        </div>
        <div class="form-group row bg-row border border-secondary py-1">
          <label for="tratamiento" class="col-sm-3 pr-0 col-form-label text-right">
            Tratamiento:
          </label>
          <div class="col-sm-4">
            <b-input-group>
              <input
                type="text"
                class="form-control"
                v-model="parametros.tratamiento"
                placeholder="Tratamiento"
                @keyup.enter="updateTratamiento"
                :disabled="isDisabled"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="updateTratamiento"
                  :disabled="isDisabled"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-sm-2 text-center d-flex justify-content-center pl-1">
            <input type="checkbox">
          </div>
          <div class="col-sm-3">
            <input type="text" class="form-control" disabled=true>
          </div>
        </div>
        <div class="form-group row bg-row">
          <label
            for="servicios_complementarios"
            class="col-sm-3 pr-0 d-flex justify-content-end align-items-center"
          >
            Servicios Complementarios:
          </label>
          <div class="col-sm-4 py-1">
            <b-input-group>
              <input
                type="text"
                class="form-control"
                v-model="parametros.servicioComplementario"
                placeholder="Servicios Complementarios"
                @keyup.enter="updateServicioComplementarioParametros"
                :disabled="isDisabled"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="updateServicioComplementarioParametros"
                  :disabled="isDisabled"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-sm-5 bg-white"></div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="row mb-3">
          <div class="col-sm-10 pl-4 pr-5">
            <p>
              El objetivo es que en Consulta Externa, tenga un mínimo
              de registro o información que tiene que poner el Médico.
            </p>
            <p>Si es Menos podra Guardar pero no calculará su comisión.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10 offset-sm-2"
            style="border: 1px solid; margin: auto; padding: 4%;">
            <div class="row">
              <div class="col-sm-12 px-0">
                <strong class="mr-2">Activar Cotización</strong>
                <small>(Es para que Genere de forma Automática una Cotización)</small>
              </div>
            </div>
            <div class="row bg-row py-2 mb-3">
              <div class="col-sm-1 offset-sm-1 d-flex text-justify-center">
                <input type="checkbox" v-model="textoActivacion.activarRecetaMedica" disabled=true>
              </div>
              <div class="col-sm-4 pl-0">
                <strong>Receta Médica</strong>
              </div>
              <div class="col-sm-1 offset-sm-1 d-flex text-justify-center">
                <input type="checkbox" v-model="textoActivacion.activarServicio" disabled=true>
              </div>
              <div class="col-sm-4 pl-0">
                <strong>Servicios</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 px-0">
                <input type="checkbox" class="mr-1"
                v-model="textoActivacion.activarTiempoCierre" disabled=true>
                <strong class="mr-1">Activar</strong>
                <small>
                  (Es para que el sistema Cierre Automáticamente como esta esa Atención)
                </small>
              </div>
            </div>
            <div class="row bg-row py-2 mb-3">
              <div class="col-sm-4 text-right pr-0">
                <label class="col-form-label"><b>Tiempo de Cierre:</b></label>
              </div>
              <div class="col-sm-4 pr-0">
                <input
                  type="time"
                  class="form-control"
                  v-model="textoActivacion.tiempoCierre"
                  disabled=true
                >
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 px-0 text-center">
                <input type="checkbox" class="mr-1"
                v-model="textoActivacion.activarTratante">
                <strong class="mr-1">Activar Filtro "Tratante"</strong>
              </div>
            </div>
            <div class="row bg-row py-2 mb-3">
              <div class="col-sm-12 pr-0">
                <span style="color: orange;">
                  Ventas: Es cuando selecciona servicios, muestre en "Tratante" solo
                  a los medicos que estan relacionados con ese servicios y a los demas no mostrara.
                </span>
              </div>
            </div>
            <div class="row float-right">
              <button class="btn bg-custom-green mr-2"
              @click="storeActivacionTexto">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: -5%">
      <div class="col-sm-7">
        <div class="row mb-1">
          <div class="col-sm-12 pl-4 pr-5">
            <p>
              Esto es de Internacion de Servicios Complementarios,
              para que no se muestre o no se use.
            </p>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-sm-12 offset-sm-2"
            style="border: 1px solid; margin: auto; padding: 2%;">
            <div class="row mb-1">
              <div class="col-sm-4 px-0 text-center">
                <input type="checkbox" class="mr-1"
                  v-model="checkEvolucionMedica">
                <strong class="mr-1">Evolucion Medica</strong>
              </div>
              <div class="col-sm-4 px-0 text-center">
                <input type="checkbox" class="mr-1"
                  v-model="checkServicioExt">
                <strong class="mr-1">Servicios(Ext).</strong>
              </div>
              <div class="col-sm-4 px-0 text-center">
                <input type="checkbox" class="mr-1"
                  v-model="checkServicioInt">
                <strong class="mr-1">Servicios(Int).</strong>
              </div>
            </div>
            <div class="row float-right">
              <button class="btn bg-custom-green mr-2"
                @click="storeServicioInternacion">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'TheTextos',
  data() {
    return {
      parametros: {
        id: 0,
        motivoConsulta: '',
        enfermedadActual: '',
        examenFisico: '',
        tratamiento: '',
        servicioComplementario: '',
      },
      isDisabled: true,
      textoActivacion: {
        id: 0,
        activarTiempoCierre: false,
        activarTratante: false,
        activarRecetaMedica: false,
        activarServicio: false,
        tiempoCierre: null,
      },
      checkEvolucionMedica: false,
      checkServicioInt: false,
      checkServicioExt: false,
    };
  },
  created() {
    this.getParametrosTextos();
  },
  methods: {
    async getParametrosTextos() {
      this.isDisabled = true;
      try {
        const response = await axios.get('/clinic/servicio_complementario/nombre_parametro_servicio_consulta/create');
        const parametros = response.data.data.nombre_parametro_servicio_consulta;
        if (parametros.length !== 0) {
          const [data] = parametros;
          this.parametros.id = data.id;
          this.parametros.motivoConsulta = data.motivo_consulta;
          this.parametros.enfermedadActual = data.enfermedad_actual;
          this.parametros.examenFisico = data.examen_fisico;
          this.parametros.tratamiento = data.tratamiento;
          this.parametros.servicioComplementario = data.servicio_complementario;
        }
        const activacionTexto = response.data.data.texto_activacion;
        if (activacionTexto) {
          this.textoActivacion.id = activacionTexto.id;
          this.textoActivacion.activarTiempoCierre = activacionTexto.activar_tiempo_cierre;
          this.textoActivacion.activarTratante = activacionTexto.activar_filtro_tratante;
          this.textoActivacion.activarRecetaMedica = activacionTexto.receta_medica;
          this.textoActivacion.activarServicio = activacionTexto.servicio;
          this.textoActivacion.tiempoCierre = activacionTexto.tiempo_cierre;
        }
        this.checkEvolucionMedica = response.data.data.has_active_evolucion_medica;
        this.checkServicioExt = response.data.data.has_active_servicio_ext;
        this.checkServicioInt = response.data.data.has_active_servicio_int;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    async updateMotivoConsulta() {
      try {
        const REQUEST = {
          id: this.parametros.id,
          motivo_consulta: this.parametros.motivoConsulta,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', REQUEST);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.motivoConsulta = parametros.motivo_consulta;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateEnfermedadActual() {
      try {
        const REQUEST = {
          id: this.parametros.id,
          enfermedad_actual: this.parametros.enfermedadActual,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', REQUEST);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.enfermedadActual = parametros.enfermedad_actual;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateExamenFisico() {
      try {
        const REQUEST = {
          id: this.parametros.id,
          examen_fisico: this.parametros.examenFisico,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', REQUEST);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.examenFisico = parametros.examen_fisico;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateTratamiento() {
      try {
        const REQUEST = {
          id: this.parametros.id,
          tratamiento: this.parametros.tratamiento,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', REQUEST);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.tratamiento = parametros.tratamiento;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async updateServicioComplementarioParametros() {
      try {
        const REQUEST = {
          id: this.parametros.id,
          servicio_complementario: this.parametros.servicioComplementario,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/nombre_parametro_servicio_consulta', REQUEST);
        const parametros = RESPONSE.data.data.nombre_parametro_servicio_consulta;
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametros.id = parametros.id;
        this.parametros.servicioComplementario = parametros.servicio_complementario;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async storeActivacionTexto() {
      try {
        const REQUEST = {
          id: this.textoActivacion.id,
          receta_medica: this.textoActivacion.activarRecetaMedica,
          servicio: this.textoActivacion.activarServicio,
          acticar_cierre: this.textoActivacion.activarTiempoCierre,
          tiempo_cierre: this.textoActivacion.tiempoCierre,
          activar_tratante: this.textoActivacion.activarTratante,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/store_parametro_activacion_texto', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        const activacionTexto = RESPONSE.data.data;
        if (activacionTexto) {
          this.textoActivacion.id = activacionTexto.id;
          this.textoActivacion.activarTiempoCierre = activacionTexto.activar_tiempo_cierre;
          this.textoActivacion.activarTratante = activacionTexto.activar_filtro_tratante;
          this.textoActivacion.activarRecetaMedica = activacionTexto.receta_medica;
          this.textoActivacion.activarServicio = activacionTexto.servicio;
          this.textoActivacion.tiempoCierre = activacionTexto.tiempo_cierre;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    activarTiempoCierre() {
      if (this.textoActivacion.activarTiempoCierre === false) {
        this.textoActivacion.tiempoCierre = null;
      }
    },
    async storeServicioInternacion() {
      try {
        const REQUEST = {
          evolucion_medica: this.checkEvolucionMedica,
          servicio_ext: this.checkServicioExt,
          servicio_int: this.checkServicioInt,
        };
        const RESPONSE = await axios.post('/clinic/servicio_complementario/store_parametro_servicio_internacion', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
};
</script>

<style scoped>
.bg-row {
  background-color: #eee;
}
</style>
